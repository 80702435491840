import React from 'react'
import Link from '../Link'

// const buttonColor = 'bg-gray-300 hover:bg-gray-400 text-gray-800'
// const buttonColor = 'bg-purple-900 hover:bg-purple-800 text-white'
const buttonColor = `bg-transparent border-2 border-indigo-800 hover:bg-indigo-100 text-indigo-800`
const focusStyles = 'focus:outline-none focus:ring'
export const buttonStyles = `${buttonColor} ${focusStyles} text-sm uppercase py-1 px-6 rounded-full inline-flex items-center cursor-pointer`

function Button({ className, component, href, to, ...props }) {
  const moreProps = {}
  if (href) {
    component = 'a'
  } else if (to) {
    component = Link
    moreProps.unstyle = true
    moreProps.noBorder = true
  }
  const Component = component

  return (
    <Component
      {...moreProps}
      {...props}
      href={href}
      to={to}
      className={`${className || ''} ${buttonStyles}`}>
      {props.children}
    </Component>
  )
}

Button.defaultProps = {
  component: 'button'
}

export default Button
