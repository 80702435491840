import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
// import { StaticImage } from "gatsby-plugin-image"
import { Helmet } from 'react-helmet'
import { navigate } from "gatsby"
// const widthClasses = 'max-w-5xl m-auto'

function App (props) {
  const data = useStaticQuery(graphql`
  query HeaderQuery {
    site {
      siteMetadata {
        title
        description
        keywords
      }
    }
  }
  `)

  const siteMetadata = data.site.siteMetadata || {}

  // Support "deeplinking" w/ `goto` param.
  if (typeof window !== 'undefined' && window.location.search) {
    const params = new URLSearchParams(window.location.search)
    const goto = params.get('goto')
    goto && navigate(goto)
  }

  return (
    <div className="App">
      <Helmet title={siteMetadata.title} defer={false} htmlAttributes={{ lang: 'en' }}>
        {/* General tags */}
        <title>{siteMetadata.title}</title>
        <meta name="description" content={siteMetadata.description} />
        <meta name="keywords" content={siteMetadata.keywords} />
        {/* <meta name="image" content={image} /> */}
      </Helmet>
      {props.sections && props.sections.map((section, i) => {
        const componentType = section.type
        const Component = props.components[componentType] || null
        
        if (!Component) {
          console.warn('Missing component in `components`', componentType)
          return null
        }
        
        return (
          <Component key={i} {...section} />
        )
      })}
      {/* <div className="antlers">
        <StaticImage alt="" src="../../../src/images/svg/deer-antlers.svg" />
      </div> */}
    </div>
  )
}

export default App
