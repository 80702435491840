import React from 'react'
// import { useStaticQuery, graphql } from 'gatsby'
import logo from '../../images/deer-hill-text-inline.svg'
import Link from '../Link'

// export const query = graphql`
//   fragment NavFragment on PagesYamlSections {
//     links {
//       text
//       href
//     }
//   }
// `

function Nav(props) {
  const {
    // siteName,
    links,
    extraClasses
  } = props

  return (
    <nav className={`Nav font-serif tracking-widest text-gray-500 relative z-10 ${extraClasses}`}>
      <div className="pt-3 text-center md:text-left max-w-6xl m-auto"> 
        <div className="w-3/5 inline-block align-middle">
          {/* <a className="inline-block p-1 m-1" href=".">{data.site.siteMetadata.title || siteName}</a> */}
          {/* <a className="inline-block px-1 mr-4 m-1" href="/">
            <img src={logo} alt="Deer Hill Vineyards" style={{ width: '100%', maxWidth: 280 }} />
          </a> */}
          <Link to="/">
            <img src={logo} alt="Deer Hill Vineyards" style={{ width: '100%', maxWidth: 280 }} />
          </Link>
        </div>
        <div className="md:w-2/5 m-auto inline-block md:text-right my-3 mb-6 ml-2 md:ml-0" style={{ minWidth: 250 }}>
            {links.map((link, i) => {
              const LinkComp = link.href ? 'a' : Link
              if (!link.href) {
                link = {
                  ...link,
                  unstyle: true,
                  noBorder: true,
                }
              }
              return (
                <LinkComp className={`mr-2 text-sm uppercase px-1 inline-block`} {...link} key={i}>{link.text}</LinkComp>
              )
            })}
        </div>
      </div>
    </nav>
  )
}

Nav.defaultProps = {
  siteName: 'Deer Hill Vineyards',
  links: [
    {
      text: 'Store',
      href: 'https://store.deerhillvineyards.com/'
    },
    // {
    //   text: 'Events',
    //   to: '/events/'
    // },
    {
      text: 'Menu',
      to: '/menu/'
    },
    {
      text: 'Club',
      to: '/club/'
    },
    {
      text: 'Contact',
      href: '#contactSection'
    },
  ],
  extraClasses: ''
}

export default Nav;
