import React from 'react'
import { Link } from "gatsby"

function LinkComponent({ className, children, component, href, noBorder, unstyle, ...props }) {
  const moreProps = {}
  const linkStyles = `${!unstyle ? 'border-bottom text-gray-800 hover:text-gray-500' : ''} ${!noBorder ? 'border-b hover:border-gray-400' : ''}`
  const Component = component
  if (href && href.indexOf('http') > -1) {
    moreProps.rel = 'noopener noreferrer'
  }

  return (
    <Component
      {...moreProps}
      {...props}
      href={href}
      className={`${className || ''} ${linkStyles}`}>
      {children}
    </Component>
  )
}

LinkComponent.defaultProps = {
  component: Link
}

export default LinkComponent
