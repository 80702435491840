import React from 'react'

const Tracking = ({ siteId }) => {
  return (
    <div>
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${siteId}`}></script>
      <script dangerouslySetInnerHTML={{ __html: `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', '${siteId}');
      ` }} />
    </div>
  )
}

export default Tracking